<template>
	<main>
		<h1 v-if="currentClass.id">
			Edit class
		</h1>
		<h1 v-else>
			Create class
		</h1>

		<div v-if="currentClass.code" class="code">
			Class code: <pre>{{ currentClass.code }}</pre>
		</div>

		<div class="columns">
			<div>
				<label for="name">Name *</label>
				<input id="name" v-model="currentClass.name" type="text" autocomplete="off" />
			</div>
			<div>
				<label for="limit">User limit *</label>
				<input id="limit" v-model="currentClass.userLimit" type="number" />
			</div>
			<div>
				<label for="teachers">Teachers</label>
				<v-select v-model="currentClass.teachers" :options="teachers" :reduce="value => value.id" label="name" :clearable="true" :searchable="true" :multiple="true" placeholder="Select teachers" />
			</div>
		</div>

		<div>
			<table>
				<thead>
					<tr>
						<th scope="col">
							Courses
						</th>
						<th scope="col">
							&nbsp;
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="course in courses" :key="course.id">
						<td>
							{{ course.name }}
						</td>
						<td scope="col">
							<BoolInput :name="course.id" :checked="currentClass.courses.includes(course.id)" @input="toggleClass($event, course.id)" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="buttons">
			<BaseButton @click="saveChanges">
				Save changes
			</BaseButton>

			<BaseButton v-if="currentClass.id" type="delete" @click="remove">
				Delete
			</BaseButton>
		</div>
	</main>
</template>

<script>
	import Backend from '../../inc/backend';
	import BaseButton from '../../components/BaseButton.vue';
	import Bus from '../../inc/bus';
	import {isAdmin, roleGuard} from '../../inc/auth';
	import BoolInput from '../../components/BoolInput';
	import Router from '../../inc/router';
	import Store from '../../inc/store';

	export default {
		...roleGuard('Courses', 'any', 'teacher'),
		components: {
			BaseButton,
			BoolInput
		},
		data() {
			return {
				currentClass: {
					teachers: [],
					courses: []
				},
				teachers: [
					{
						id: Store.user.id,
						name: Store.user.firstName + ' ' + Store.user.lastName
					}
				],
				courses: []
			};
		},
		created() {
			if(this.$route.params.id) {
				Backend.get('classes/' + this.$route.params.id).then(res => {
					this.currentClass = res.data;
				});
			}

			if(isAdmin()) {
				Backend.get('users?role=teacher').then(res => {
					this.teachers = res.data.map(user => ({
						id: user.id,
						name: user.firstName + ' ' + user.lastName
					}));
				});
			}

			Backend.get('classes/courses').then(res => {
				this.courses = res.data;
			});
		},
		methods: {
			saveChanges() {
				if(this.$route.params.id) {
					Backend.put('classes/' + this.$route.params.id, this.currentClass)
						.then(() => Bus.emit('success', 'Changes saved!'));
				}
				else {
					Backend.post('classes', this.currentClass)
						.then(res => {
							this.$set(this.currentClass, 'id', res.data.id);
							this.$set(this.currentClass, 'code', res.data.code);

							return Router.push({name: 'AdminClassesEdit', params: {id: res.data.id}});
						})
						.then(() => Bus.emit('success', 'Changes saved!'));
				}
			},
			remove() {
				this.$modal.show('dialog', {
					title: 'Delete class?',
					text: 'This will permanently delete this class. This action is irreversible. Do you want to continue?',
					buttons: [
						{
							title: 'Cancel',
							handler: () => {
								this.$modal.hide('dialog');
							}
						},
						{
							title: 'Delete',
							class: 'vue-dialog-button danger',
							handler: () => {
								Backend.delete('classes/' + this.currentClass.id).then(() => {
									this.$modal.hide('dialog');
									Router.push({name: 'AdminClasses'});
								});
							}
						}
					]
				});
			},
			toggleClass(checked, courseId = null) {
				if(checked) {
					this.currentClass.courses.push(courseId);
				}
				else {
					this.currentClass.courses = this.currentClass.courses.filter(id => id !== courseId);
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	@include breakpoint-min('medium') {
		.columns {
			display: flex;
			gap: $default_padding;

			> * {
				flex: 1;
			}

			> :first-child {
				flex: 2;
			}
		}
	}

	.code {
		margin-bottom: $default_padding;

		pre {
			display: inline;
		}
	}

	table {
		max-width: none;
		table-layout: fixed;

		th + th,
		td + td {
			width: 72px;
			padding: 0;
			text-align: center;
		}

		label {
			margin: 0;
		}

		.global td {
			border-bottom: 2px solid rgba($color__dark, 0.2);
		}
	}
</style>